<template>
  <div :class="`suitability-questionnaire ${$isMobile ? 'is-mobile pt-embedded-in-ios' : 'pt-6'}`">
    <NavBack text="Back" :action="goBack"></NavBack>
    <MenuAndLogo v-if="$isMobile" referralIconBackgroundColor="#fff"></MenuAndLogo>
    <section class="content-wrapper">
      <div class="page-title has-text-weight-semibold has-text-centered mb-5">Suitability Questionnaire</div>
      <Questionnaire
        :onLoad="questionnaireOnLoad"
        :onChange="questionnaireOnChange"
        type="suitability"
      ></Questionnaire>
    </section>
    <footer v-if="showBottomButtons" class="footer-buttons">
      <BottomButtons
        backButtonText="Back"
        :nextButtonText="fromProfilePage ? 'Save' : 'Next'"
        :nextDisabled="!questionnaireCompleted"
        :loading="nextButtonIsLoading"
        :showSteps="false"
        @goNext="goNext"
        @goBack="goBack"
      ></BottomButtons>
    </footer>
    <DisclaimerModal
      @closeDisclaimerModal="closeDisclaimerModal"
      type="atlasOneQuestionnaireDisclaimer"
      v-if="showDisclaimer"
    ></DisclaimerModal>
  </div>
</template>
<script>
import NavBack from '@components/desktop/header-nav-back.vue'
import Questionnaire from '@views/profile/questionnaire/QuestionsForm.vue'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import questionnaireMixins from '@utils/mixins/questionnaire-mixins.js'
import DisclaimerModal from '@components/modal/disclaimer-modal.vue'
import { saveQuestionnaire } from '@api/profile'
import { embeddedInIos } from '@utils/common-methods/common'

export default {
  mixins: [questionnaireMixins],
  data() {
    return {
      nextButtonIsLoading: false,
      showDisclaimer: false,
    }
  },
  components: {
    NavBack,
    Questionnaire,
    MenuAndLogo,
    BottomButtons,
    DisclaimerModal,
  },
  computed: {
    nameSpaceUrl() {
      return this.$route.query.distributor
    },
    propertyId() {
      return this.$route.query.propertyId
    },
    fromProfilePage() {
      return !this.propertyId
    },
    membershipType() {
      return JSON.parse(localStorage.getItem('investor')).membershipType.toLowerCase()
    },
    investorAdministrativeAreaLevel1() {
      return JSON.parse(localStorage.getItem('investor')).administrativeAreaLevel1
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'eligibilityQuestionnaire') localStorage.removeItem('suitabilityAnswers')
    next()
  },
  methods: {
    goBack() {
      if (embeddedInIos()) return window.webkit.messageHandlers.closeWebview.postMessage(true)
      this.$router.go(-1)
    },
    goNext() {
      if (!this.questionnaireCompleted) return
      this.nextButtonIsLoading = true

      const payload = this.convertAnswersToArrayOnly()
      if (this.checkEligibility()) { // to eligibility questionnaire
        localStorage.setItem('suitabilityAnswers', JSON.stringify(payload))
        const eligibilityRoute = this.$route.fullPath.replace('suitability', 'eligibility')
        this.$router.push(eligibilityRoute)
      } else { // save questionnaire and continue
        saveQuestionnaire(payload).then((res) => {
          if (!res.success) return

          if (this.fromProfilePage) {
            if (embeddedInIos()) return window.webkit.messageHandlers.closeWebview.postMessage(true)
            this.$router.push('/investorProfile')
          } else this.showDisclaimer = true
        })
      }
    },
    closeDisclaimerModal() {
      if (embeddedInIos()) return window.webkit.messageHandlers.questionnaireSaved.postMessage(this.propertyId)
      this.$router.push(`/${this.nameSpaceUrl}/buy/amount/${this.propertyId}`)
    },
    checkEligibility() {
      if (this.investorAdministrativeAreaLevel1 === 'BC') return false

      const nfa = this.answers.net_financial_assets
      const maxNFA = this.parseStringRangeToCeilingInt(nfa)
      return this.membershipType.includes('believer') ? maxNFA >= 7000 : maxNFA >= 7500
    },
    parseStringRangeToCeilingInt(string) {
      return Number(string.split(' - ').pop().replace(/\$|,|\+/g, ''))
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.suitability-questionnaire {
  min-height: 100vh;
  background-color: #F0EEF8;
  padding-bottom: 50px;
  .content-wrapper {
    padding: 0 24px;
    .page-title {
      font-size: 32px;
      line-height: 38px;
    }
  }
  .footer-buttons {
    padding: 24px;
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .suitability-questionnaire {
    border-radius: 12PX;
  }
}
</style>
